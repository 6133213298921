@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: Agreverance;
  src: url(../fonts/Agreverance-roman.ttf);
}

@import './base/reset';
@import './layout/aboutMe';
@import './layout/advantages';
@import './layout/footer';
@import './layout/form';
@import './layout/header';
@import './layout/instagram';
@import './layout/reviews';
@import './layout/services';
@import './layout/swiper';
@import './Components/container';

@import './layout/modal';

@import './Components/demo';
